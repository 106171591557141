// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("./../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-guide-connaissance-client-js": () => import("./../../src/pages/guide-connaissance-client.js" /* webpackChunkName: "component---src-pages-guide-connaissance-client-js" */),
  "component---src-pages-guide-investissement-rationnel-js": () => import("./../../src/pages/guide-investissement-rationnel.js" /* webpackChunkName: "component---src-pages-guide-investissement-rationnel-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sites-web-js": () => import("./../../src/pages/sites-web.js" /* webpackChunkName: "component---src-pages-sites-web-js" */)
}

